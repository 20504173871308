.unexpanded-inventory-container {
    position: absolute;
    bottom: 0;
    top: 0;
    margin-top: 123.2%;
    margin-left: 26.2%;
    height: 9.4%;
    width: 68.8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unexpanded-items-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83%;
    width: 95.5%;

    &::before {
        background-image: url('../../../../assets/dashboardScreen/unexpanded_Inventory_Container.png');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.3;
        z-index: 1;
    }

    > * {
        z-index: 10;
    }

    > img {
        width: 102.5%;
        max-width: 102.5%;
        margin-left: -1.2%;
        margin-top: -1%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .items-buttons {
        display: block;
        width: 92%;
        height: 87%;

        div {
            height: 100%;
        }

        .item {
            position: relative;
            height: 100%;
            width: 100%;
            border: none;
            background-image: url('../../../../assets/dashboardScreen/Inventory-bg.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;

            .item-image {
                position: absolute;
                top: 0;
                left: 0;
                padding: 21% 7% 9% 7%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    background-color: white;
                }
            }

            .item-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Outage;
                padding: 21% 7% 9% 7%;

                &:hover {
                    opacity: 1;
                }

                div {
                    position: relative;
                    font-size: 1.4vw;
                    width: 100%;
                    height: 100%;
                    word-break: break-word;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (min-width: 1500px) {
                        font-size: 20px;
                    }

                    span {
                        position: relative;
                        z-index: 1000;
                    }

                    &::before {
                        background-color: #72f4fd;
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        left: 0px;
                        opacity: 0.7;
                    }
                }
            }

            .item-text {
                width: fit-content;
                height: 13%;
                display: flex;
                align-items: center;

                span {
                    color: #020000;
                    font-family: DINNextLTPro-Bold;
                    text-transform: uppercase;
                    font-size: 1vw;
                    text-align: left;
                    letter-spacing: 0;
                    padding-left: 5%;
                    width: 7.25vw;
                    height: 1vw;
                    display: inline-block;
                    align-items: center;
                    overflow: hidden;
                    word-break: break-all;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    @media screen and (min-width: 1500px) {
                        font-size: 15px;
                        height: 90%;
                    }
                }
            }
        }
    }
}

.popup {
    height: 400%;
    width: 94%;
    z-index: 5555;
    position: absolute;
    top: -420%;
    left: -8%;
    background-color: transparent;
    background-image: url('../../../../assets/dashboardScreen/InventoryPopup.png');
    background-repeat: no-repeat;
    background-size: contain;

    > img {
        width: 84%;
        max-width: 72%;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -44.5%);
    }
}

.cross {
    width: 13%;
    height: 11%;
    position: absolute;
    top: 9%;
    left: 87%;
    background-color: transparent;
    border: none;
}
