.chatbox {
    position: absolute;
    width: 19.8%;
    height: 18.2%;
    margin-left: 74.8%;
    margin-top: 84.3%;
    top: 0;
    left: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../../../assets/dashboardScreen/PlayerStatus_Container.png');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.3;
        z-index: 1;
    }

    > * {
        z-index: 10;
    }

    img {
        position: absolute;
        left: 0;
        max-width: 108%;
        width: 108%;
        margin-left: -3.7%;
        margin-top: -4.8%;
    }
}

.textarea {
    font-size: 1.4vw;
    font-family: DINNextLTPro-Medium;
    color: white;
    margin-left: 7%;
    flex: 1;
    background-color: transparent;
    border: none;
    overflow: auto;
    resize: none;

    &:focus {
        outline: none;
    }

    @media screen and (min-width: 1500px) {
        font-size: 20px;
    }
}

.response-image {
    width: 100%;
    background-image: url('../../../../assets/dashboardScreen/ResponseBox.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 13.42%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-submit {
    background-image: url('../../../../assets/dashboardScreen/ChatBox_SubmitArrow.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    height: 21%;
    margin-right: 5.45%;
    width: 8%;
    border: none;
}

.chat-messages-container {
    position: relative;
    overflow: auto;
    background-color: transparent;
    border: none;
    overflow-y: scroll;
    width: 96%;
    top: 0;
    left: 0;
    flex: 1;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.chat-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    height: 100%;
    padding: 3.4%;
}

.message-item {
    display: block;
    text-align: center;
}

.msg-right {
    margin-left: auto;
    text-align: right;

    .message-bubble {
        background-color: #7cf5fc;
        border-radius: 10px 10px 0px 10px;
    }
}

.msg-left {
    margin-right: auto;
    text-align: left;

    .message-bubble {
        background-color: #fff;
        border-radius: 10px 10px 10px 0px;
    }
}

.message-bubble {
    &.set-margin-top {
        margin-top: 0%;
        margin-bottom: 2.5%;
    }
    text-align: left;
    font-size: 1.4vw;
    font-family: DINNextLTPro-Medium;
    position: relative;
    display: inline-block;
    height: auto;
    color: #000;
    padding: 2% 10%;
    margin-top: 5%;
    margin-bottom: 3%;
    max-width: 98%;
    white-space: pre-line;
    word-wrap: break-word;
    line-height: 150%;
    cursor: pointer;

    @media screen and (min-width: 1500px) {
        font-size: 20px;
    }
}

.sender-name {
    font-family: Ethnocentric;
    font-size: 1.5vw;
    color: #fff;
    padding-left: 9%;
    margin-top: 5%;
    @media screen and (min-width: 1500px) {
        font-size: 22px;
    }
}

.scroll-dummy-scroll {
    position: relative;
    bottom: -20%;
}

.notification-success {
    position: relative;
    text-align: center;
    height: 28%;
    width: 90%;
    margin-left: 5%;
    padding: 2%;
    margin-bottom: 3%;
    color: #ffffff;
    font-family: DINNextLTPro-Bold;
    font-size: 1.1vw;

    @media screen and (min-width: 1500px) {
        font-size: 17px;
    }

    border: solid;
    border-color: white;
    border-width: thin;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../../../assets/dashboardScreen/BlueFill.png');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.37;
    }
    > span {
        text-align: center;
        position: relative;
    }

    .result-button {
        margin-top: 4%;
        width: 43%;
        z-index: 555;
        position: relative;
        background: transparent;
        border: none;
        opacity: 1;
        padding: 0;

        > img {
            max-width: 100%;
            position: relative;
            margin: 0;
        }
    }
}
