body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Ethnocentric';
    src: local('Ethnocentric'),
        url(./assets/fonts/ethnocentric\ rg.ttf) format('truetype');
}

@font-face {
    font-family: 'Ethnocentric Italic';
    src: local('Ethnocentric Italic'),
        url(./assets/fonts/ethnocentric\ rg\ it.ttf) format('truetype');
}

@font-face {
    font-family: 'Outage';
    src: local('Outage'), url(./assets/fonts/Outage.ttf) format('truetype');
}

@font-face {
    font-family: 'Outage Cut';
    src: local('Outage Cut'), url(./assets/fonts/Outage\ Cut.ttf) format('truetype');
}

@font-face {
    font-family: 'DINNextLTPro-Medium';
    src: local('DINNextLTPro-Medium'),
        url(./assets/fonts/DINNextLTPro-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'DINNextLTPro-Bold';
    src: local('DINNextLTPro-Bold'),
        url(./assets/fonts/DINNextLTPro-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'DINNextLTProBoldCondensed';
    src: local('DINNextLTProBoldCondensed'),
        url(./assets/fonts/DINNextLTProBoldCondensed.ttf) format('truetype');
}

* {
    box-sizing: border-box;
}
