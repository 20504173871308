.mission-control-container {
    position: absolute;
    height: 29.3%;
    width: 88.5%;
    margin-left: 5.8%;
    margin-top: 20.75%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    img {
        max-width: 104%;
        margin-left: -1.9%;
        margin-top: -1.8%;
        position: absolute;

        &.logo {
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            width: 46.65%;
        }
    }

    .background {
        margin: 0;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        width: 100%;
    }

    > * {
        z-index: 10;
        position: relative;
    }
}

.back-button {
    width: 13%;
    height: 7.5%;
    background-image: url('../../../../assets/dashboardScreen/back-button.png');
    background-size: contain;
    background-color: transparent;
    border: none;
    margin-top: 57.3%;
    background-repeat: no-repeat;
    z-index: 100;
    position: absolute;

    &:hover {
        background-image: url('../../../../assets/dashboardScreen/back-button-hover.png');
    }
}

.mission-control-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .mission-control {
        position: absolute;
        display: block;
        height: 92%;
        width: 92%;
        border-radius: 15px;
        font-family: DINNextLTProBoldCondensed;

        canvas {
            position: relative;
        }
    }

    .video-player {
        width: 100%;
        height: 100%;

        > div {
            z-index: 1000;
            width: 100%;
            height: 100%;
        }
    }
}

.mission-control-host-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    .start-game {
        border: none;
        background-color: #7cf5fc;
        width: 13%;
        height: 13%;
        border-radius: 10px;
        font-family: DINNextLTProBoldCondensed;
        font-size: 1.46vw;

        @media screen and (min-width: 1500px) {
            font-size: 22px;
        }
    }
}
