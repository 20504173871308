.expanded-inventory-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 12.1%;
    width: 96.14%;
    margin-top: 121.5%;
    margin-left: 2.25%;
    background-image: url('../../../../assets/dashboardScreen/RobotExpanded_WhiteBoxScreen.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;

    > div {
        position: relative;
        margin-top: 3.3%;
        display: flex;
        height: 63.5%;

        &::before {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 0.3;
            z-index: 1;
        }

        > * {
            z-index: 10;
        }
    }
}

.host-inventory-block {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 124%;
    height: 9.4%;
    width: 88.1%;
    margin-left: 6.2%;

    .expanded-inventory-container {
        margin: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-image: none;

        > img {
            position: absolute;
        }
    }
}

.items-container {
    width: 40.5%;
    margin-left: 3.91%;
    flex-direction: column;
    align-items: center;

    &.host-items {
        width: 96.1%;
        height: 80%;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &::before {
            background-image: url('../../../../assets/hostDashboard/HostDash_InventoryContainer.png');
        }

        .items-buttons {
            width: 94%;
            height: 87%;
            flex: none;

            .slick-slide > div {
                margin-right: 17.4%;
                margin-left: 6%;
            }

            .slick-list {
                width: 94%;
            }

            .slick-prev {
                left: -1%;
            }

            .slick-next {
                right: -1%;
            }

            .item {
                margin: 0;
                opacity: 1;

                .item-image {
                    padding: 21% 7% 8% 7%;

                    img {
                        padding: 15%;
                    }
                }

                .item-overlay {
                    padding: 21% 7% 8% 7%;

                    div {
                        font-size: 1.4vw;

                        @media screen and (min-width: 1500px) {
                            font-size: 20px;
                        }
                    }
                }

                .item-text {
                    span {
                        font-size: 0.85vw;
                        height: 0.85vw;

                        @media screen and (min-width: 1500px) {
                            font-size: 15px;
                            height: 90%;
                        }
                    }
                }
            }
        }

        > img {
            width: 101.9%;
            max-width: 101.9%;
            margin-left: -0.8%;
            margin-top: -1%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    > img {
        width: 102.8%;
        max-width: 102.8%;
        margin-left: -1.5%;
        margin-top: -1.7%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &::before {
        background-image: url('../../../../assets/dashboardScreen/RobotExpanded_Container.png');
    }

    .items-buttons {
        display: block;
        width: 100%;
        height: 65%;

        div {
            height: 100%;
        }

        .slick-list {
            width: 88%;
        }

        .slick-prev {
            left: 1%;
        }

        .slick-next {
            right: 1%;
        }

        .item {
            position: relative;
            height: 100%;
            width: 100%;
            border: 1px solid transparent;
            background-image: url('../../../../assets/dashboardScreen/Inventory-bg.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
            margin-left: 7.27%;
            margin-right: 7.27%;
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            opacity: 0.43;

            .item-image {
                position: absolute;
                top: 0;
                left: 0;
                padding: 21% 7% 7% 7%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    background-color: white;
                }
            }

            .item-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Outage;
                padding: 21% 7% 7% 7%;

                &:hover {
                    opacity: 1;
                }

                div {
                    position: relative;
                    font-size: 1.1vw;
                    width: 100%;
                    height: 100%;
                    word-break: break-word;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (min-width: 1500px) {
                        font-size: 17px;
                    }

                    span {
                        position: relative;
                        z-index: 1000;
                    }

                    &::before {
                        background-color: #72f4fd;
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        left: 0px;
                        opacity: 0.7;
                    }
                }
            }

            .item-text {
                width: fit-content;
                height: 13%;
                display: flex;
                align-items: center;

                span {
                    color: #020000;
                    font-family: DINNextLTPro-Bold;
                    text-transform: uppercase;
                    font-size: 0.8vw;
                    text-align: left;
                    letter-spacing: 0;
                    padding-left: 5%;
                    width: 5.25vw;
                    height: 0.8vw;
                    display: inline-block;
                    align-items: center;
                    overflow: hidden;
                    word-break: break-all;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    @media screen and (min-width: 1500px) {
                        font-size: 10px;
                        height: 80%;
                    }

                    &::after {
                        content: '\0000a0';
                        display: inline-block;
                        width: 1px;
                    }
                }
            }

            &.opacity-full {
                opacity: 1;
            }
        }
    }

    .items-heading {
        color: #ffffff;
        font-family: Ethnocentric;
        font-size: 1vw;
        text-align: center;
        letter-spacing: 0;
        margin: 2.78% 0 2.78% 0;

        @media screen and (min-width: 1500px) {
            font-size: 15px;
        }
    }
}

.selectables-container {
    width: 31.6%;
    margin-left: 1.55%;

    img {
        max-width: 104%;
        width: 104%;
        margin-left: -1.8%;
        margin-top: -2.3%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &::before {
        background-image: url('../../../../assets/dashboardScreen/RobotExpanded_Container2.png');
    }
}

.robot-expanded-submit-command-button {
    &.action-not-allowed {
        cursor: not-allowed;
        opacity: 0.3;
    }

    position: relative;
    width: 15.84%;
    height: 65.13%;
    margin-left: 1.69%;
    margin-top: 3.12%;
    border: 1px solid transparent;
    background-image: url('../../../../assets/dashboardScreen/RobotExpanded_SubmitCommandButtton.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.robot-expanded-robot-icon {
    height: 38%;
    width: 43%;
    max-height: 38%;
    margin-bottom: 7%;
}

.submit-command {
    color: #72f4fd;
    font-family: Outage;
    font-size: 1.73vw;
    text-align: center;
    letter-spacing: 0;

    @media screen and (min-width: 1500px) {
        font-size: 26px;
    }
}

.selectable-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .selectable-header {
        color: #ffffff;
        font-family: Ethnocentric;
        font-size: 1vw;
        text-align: center;
        letter-spacing: 0;
        margin-top: 4%;

        @media screen and (min-width: 1500px) {
            font-size: 15px;
        }
    }

    .selectable-buttons {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 86%;
        height: 100%;
        padding-bottom: 3%;

        button {
            display: block;
            color: #000000;
            background-color: #4aace5;
            width: 100%;
            height: 33%;
            flex: 1;
            margin-top: 4%;
            border: 1px solid transparent;
            font-family: Outage Cut;
            text-align: center;
            font-size: 1.1vw;
            max-height: 19%;
            opacity: 0.743;
            padding: 2%;

            @media screen and (min-width: 1500px) {
                font-size: 15px;
            }

            &:hover,
            &.selected {
                background: #7df6fc;
                opacity: 1.043;
                border: 1px solid white;
                box-shadow: 0 0 5px white;
            }
        }
    }
}

.item-tag {
    position: absolute;
    display: flex;
    margin-top: 21%;
    top: 0;
    width: 86%;
    justify-content: center;
    align-items: center;
    max-height: 12%;
    opacity: 0.75;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.75;
        z-index: 1;
    }

    &.added::before {
        background-color: #52ff82;
    }

    &.not-added::before {
        background-color: #f01e1e;
    }

    .item-tag-text {
        z-index: 10;
        font-family: DINNextLTPro-Bold;
        font-size: 0.73vw;
        color: #04063f;
        @media screen and (min-width: 1500px) {
            font-size: 11px;
        }
    }
}

.button-to {
    position: absolute;
    right: 0;
    bottom: 2%;
    display: block;
    background-color: #72f4fd;
    background-size: cover;
    background-repeat: no-repeat;
    width: 21.6%;
    height: 18%;
    border: none;
    padding: 6%;
}

.left-swipe {
    width: 3%;
    height: 3%;
    position: relative;
    align-self: flex-start;
}

.right-swipe {
    width: 3%;
    height: 3%;
    position: relative;
    align-self: flex-end;
}
