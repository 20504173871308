.login-wrapper {
    div img {
        max-width: 100%;
        width: 28%;
    }

    .wrapper {
        max-width: 1500px;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login-background {
        position: relative;
        background-image: url('../../assets/loginScreen/Login_Background.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 0;
        max-width: 1401px;
        padding-top: 114.2%;
    }

    .background {
        background-color: #cacaca !important;
        display: flex;
        justify-content: center;
        padding: 48px 46px 55px 46px;
        flex: 1;
        width: 100%;
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
    }

    .hidden {
        visibility: hidden;
    }

    .mission-mars-logo-login {
        position: absolute;
        display: flex;
        width: 100%;
        height: 18.25%;
        padding: 0.75%;
        top: 0;
        justify-content: center;
    }

    .escape-games-logo-login {
        position: absolute;
        width: 33.5%;
        height: 3.25%;
        margin-left: 35.42%;
        margin-top: 105.75%;
        top: 0;
    }

    .login-heading {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 31%;
        margin-left: 24%;
        font-size: 6vw;
        color: #fff;
        font-family: Ethnocentric;
        @media screen and (min-width: 1500px) {
            font-size: 90px;
        }
    }

    .login-container {
        width: 46.54%;
        margin-left: 27.1%;
        position: absolute;
        margin-top: 48.3%;
        height: 40.5%;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    .login-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
    }

    .login-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 0%;
        height: 18.12%;
        margin-top: 3%;
        background-color: transparent;
        border-radius: 10px 10px 10px 10px;
        border-color: white;

        &::before {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-image: url('../../assets/loginScreen/TextFieldBubble.png');
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.35;
        }

        input {
            color: black;
            font-family: DINNextLTPro-Bold;
            position: relative;
            font-size: 2.1vw;
            text-align: center;
            flex: 1 1;
            background-color: transparent;
            border: none;
            overflow: auto;
            resize: none;
            width: 100%;

            @media screen and (min-width: 1500px) {
                font-size: 29px;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .terms-container {
        display: flex;
        flex-direction: column;
        margin-top: 5.37%;
    }

    .submit {
        color: black;
        font-family: Outage;
        background-color: #fff;
        border-radius: 50px;
        border-color: white;
        font-size: 2.07vw;
        height: 13.8%;
        margin-top: auto;
        border: none;

        @media screen and (min-width: 1500px) {
            font-size: 31px;
        }

        &:disabled {
            opacity: 0.8;
        }
    }

    .checkboxes {
        background-color: transparent;
        width: 3.8%;
        height: 105%;
    }

    .checkbox-text {
        font-family: DINNextLTPro-Bold;
        margin-bottom: 1.8%;
        color: #fff;
        text-align: justify;
        display: flex;
        align-items: center;
        font-size: 1.2vw;

        @media screen and (min-width: 1500px) {
            font-size: 17px;
        }

        span {
            margin-left: 2.1%;
        }

        a {
            color: #72f4fd;
        }
    }

    textarea::placeholder {
        color: black;
    }

    textarea:focus::placeholder {
        color: transparent;
    }
}

.Toastify__toast-container--top-center {
    width: 35% !important;
}

.Toastify__toast {
    text-transform: capitalize;
    font-family: DINNextLTPro-Bold !important;
    font-size: 1.5vw !important;
    border-radius: 10px !important;

    a {
        color: white;
    }

    @media screen and (min-width: 1500px) {
        font-size: 22px !important;
    }
}
