.App {
    text-align: center;
    display: flex;
    justify-content: center;
    max-width: 100vw;
    overflow: hidden;
    background-color: #cccccc;
}

.hide {
    display: none !important;
}

.app-center {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: Ethnocentric;
}
