.time-remaining-container {
    border: 1px solid transparent;
    border-image: url('../../../../assets/dashboardScreen/TimeRemainingBlueBorder.png') 1
        round;
    position: absolute;
    width: 21.1%;
    height: 3.4%;
    margin-left: 44.7%;
    margin-top: 5%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #71f4fc;
    font-family: Ethnocentric;
    font-size: 4.26vw;
    text-align: center;

    @media screen and (min-width: 1500px) {
        font-size: 64px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../../../assets/dashboardScreen/TimeRemainingGradient.png');
        opacity: 0.3;
    }

    &.host {
        font-size: 2vw;
        text-align: center;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        @media screen and (min-width: 1500px) {
            font-size: 32px;
        }
    }
}

.host-timer {
    position: absolute;
    margin-top: 24%;
    top: 0;
    left: 0;
    width: 20.3%;
}

.host-time-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 42.77%;
    align-items: center;
    border-right: 2px solid #7cf5fc;

    .host-time {
        display: flex;
        align-items: center;
        flex: 1;
    }
}

.play-pause {
    background-image: url('../../../../assets/hostDashboard/HostDash_PlayPauseButton.png');
    position: relative;
    height: 46.5%;
    width: 100%;
    border: none;

    img {
        height: 70%;
    }
}

.controls {
    height: 100%;
    flex: 1;
    display: flex;

    div {
        flex: 1;
        height: 100%;
    }
}

.change {
    padding: 4.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .heading {
        width: 100%;
        color: #fff;
        font-family: Ethnocentric;
        font-size: 1.6vw;
        @media screen and (min-width: 1500px) {
            font-size: 24px;
        }
    }

    button {
        position: relative;
        height: 38%;
        width: 48%;
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        border-radius: 4px;
        background-color: transparent;

        &.increment {
            background-image: url('../../../../assets/hostDashboard/Host_Dash_Add.png');
        }

        &.decrement {
            background-image: url('../../../../assets/hostDashboard/Host_Dash_Subtract.png');
        }
    }
}
