body::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;

    content: url('../../assets/dashboardScreen/BackgroundPanelsHost.png')
        url('../../assets/dashboardScreen/BackgroundPanels.png')
        url('../../assets/dashboardScreen/BackgroundPanels_NoInventory.png');
}

div img {
    max-width: 100%;
}

button {
    outline: none;
    cursor: pointer;
}

.wrapper {
    width: 100vw;
    max-width: 1500px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.background {
    background-color: #cacaca !important;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
}

.hidden {
    visibility: hidden;
}

.mission-mars-logo {
    position: absolute;
    width: 18.5%;
    height: 7.25%;
    margin-left: 1.42%;
    margin-top: 0.75%;
    top: 0;
}

.background-panels {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 0;
    max-width: 1506px;
    padding-top: 170.25%;

    &.host {
        background-image: url('../../assets/dashboardScreen/BackgroundPanelsHost.png');
    }

    &.player {
        background-image: url('../../assets/dashboardScreen/BackgroundPanels.png');
        margin-bottom: -22.6%;
    }

    &.player-expanded {
        background-image: url('../../assets/dashboardScreen/BackgroundPanels_NoInventory.png');
        margin-bottom: -22.6%;
    }
}

.zoom-background-button {
    position: absolute;
    width: 22.1%;
    height: 3.31%;
    margin-left: 72.3%;
    margin-top: 5%;
    top: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid transparent;
    border-image: url('../../assets/dashboardScreen/ZoomBlueBorder.png') 1 round;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../assets/dashboardScreen/ZoomGradient.png');
        background-repeat: no-repeat;
        opacity: 0.3;
        z-index: 1;
    }

    button {
        position: relative;
        font-family: Outage Cut;
        font-size: 1.2vw;
        background-color: #72f4fd;
        width: 90%;
        height: 75%;
        border: 1px white;
        border-radius: 4px;

        @media screen and (min-width: 1500px) {
            font-size: 20px;
        }
    }

    > * {
        z-index: 10;
    }
}

.robot-expanded-container {
    position: absolute;
    bottom: 0;
    height: 18.91%;
    width: 101.14%;

    background-image: url('../../assets/dashboardScreen/RobotExpanded_WhiteBoxScreen.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.arrow-button {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background-color: transparent;
    margin-top: 128.4%;
    width: 3%;
    height: 4%;

    &.contract {
        margin-left: 96%;
    }

    &.expand {
        margin-left: 20.5%;
    }
}

::-webkit-scrollbar {
    width: 0.4rem;
}

::-webkit-scrollbar-track {
    background: #06133c4f;
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
    background: #0a1d51;
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.slick-prev {
    background-image: url('../../assets/dashboardScreen/Inventory_LeftButton.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 34%;
    width: 5.5%;

    &::before {
        font-family: none;
        font-size: 0px;
    }
}

.slick-next {
    background-image: url('../../assets/dashboardScreen/Inventory_RightButton.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 34%;
    width: 5.5%;

    &::before {
        font-family: none;
        font-size: 0px;
    }
}

.slick-list {
    width: 94%;

    > .slick-track {
        margin-left: 0;
    }
}

.slick-slider {
    display: flex;
    justify-content: center;
}

.slick-slide > div {
    margin-right: 14.4%;
}

.robot-button-container {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 125.3%;
    margin-left: 5.2%;
    width: 13.9%;
    height: 7.1%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../assets/dashboardScreen/RobotControl_Container.png');
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.3;
        z-index: 1;
    }

    > * {
        z-index: 10;
    }

    > img {
        position: absolute;
        margin-top: 0.1%;
        max-width: 101%;
    }

    .icon {
        height: 75%;
    }
}
