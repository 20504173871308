.generate-password-container {
    width: 100vw;
    max-width: 1500px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    justify-content: center;
    flex: 1;

    .heading {
        font-size: 4vw;
        color: #020000;
        font-family: Ethnocentric;
        display: flex;
        justify-content: center;
        width: 100%;

        @media screen and (min-width: 1500px) {
            font-size: 90px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 27%;
        width: 46.54%;

        .input-area {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-left: 0%;
            height: 48.7%;
            margin-top: 3%;
            background-color: transparent;
            border-radius: 10px 10px 10px 10px;
            border-color: white;

            &::before {
                content: '';
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                background-color: white;
                border-radius: 10px 10px 10px 10px;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 0.35;
            }

            input {
                color: black;
                font-family: DINNextLTPro-Bold;
                position: relative;
                font-size: 2.1vw;
                text-align: center;
                flex: 1 1;
                background-color: transparent;
                border: none;
                overflow: auto;
                resize: none;
                width: 100%;

                @media screen and (min-width: 1500px) {
                    font-size: 29px;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .submit {
        color: black;
        font-family: Outage;
        background-color: #fff;
        border-radius: 50px;
        border-color: white;
        font-size: 2.07vw;
        height: 34.8%;
        margin-top: 3%;
        border: none;
        cursor: pointer;

        @media screen and (min-width: 1500px) {
            font-size: 31px;
        }

        &:disabled {
            opacity: 0.8;
        }
    }
}

.passwords-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    flex-wrap: wrap;
    padding: 3%;
    font-family: DINNextLTPro-Bold;

    > * {
        flex: 1 1 20%;
        display: flex;
        align-items: center;
    }
}
