.map-container {
    position: absolute;
    height: 21.3%;
    width: 59.6%;
    margin-left: 6.7%;
    margin-top: 80.7%;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../../../assets/dashboardScreen/Map_Container.png');
        background-size: contain;
        opacity: 0.3;
        z-index: 1;
    }

    img {
        max-width: 100%;
        margin-left: 0.42%;
        position: absolute;
    }

    > * {
        z-index: 10;
        position: relative;
    }
}

.map-wrapper {
    position: absolute;
    height: 100%;
    width: 104.2%;
    margin-left: -2%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .map {
        display: block;
        height: 100%;
        width: 100%;

        canvas {
            position: relative;
        }
    }
}
