.voting-container {
    position: absolute;
    width: 19.8%;
    height: 18.2%;
    margin-left: 74.8%;
    margin-top: 84.3%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../../../assets/dashboardScreen/PlayerStatus_Container.png');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.3;
        z-index: 1;
    }

    > * {
        z-index: 10;
    }

    > img:first-of-type {
        width: 117.7%;
    }

    img {
        position: absolute;
        left: 0;
        max-width: 117.7%;
        margin-left: -9.1%;
        margin-top: -9.1%;
    }

    .divider {
        margin-top: 33%;
        margin-left: 1%;
    }

    .heading {
        display: flex;
        position: relative;
        width: 100%;
        height: 18%;
        color: #ffffff;
        font-family: Ethnocentric;
        text-align: left;
        justify-content: center;
        flex-direction: column;
        font-size: 1.2vw;
        border-bottom: 2px solid #7cf5fc;
        padding: 6% 13%;
        line-height: 1.2vw;

        &.host {
            height: 15%;
        }

        @media screen and (min-width: 1500px) {
            font-size: 18px;
        }
    }

    .vote-box {
        margin: 3%;
        display: flex;
        position: relative;
        width: 85%;
        color: #ffffff;
        font-family: DINNextLTProBoldCondensed;
        text-align: center;
        flex-direction: column;
        justify-content: space-around;
        font-size: 2vw;
        flex: 1;

        @media screen and (min-width: 1500px) {
            font-size: 30px;
        }

        .different-color {
            color: #69fafe;
        }
    }

    .new-voting-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60%;

        .vote-button {
            position: relative;
            height: 28.8%;
            margin-bottom: 3.5%;
            border-radius: 40px;
            background: transparent;
            border: 2px solid #7cf5fc;
            display: flex;
            align-items: center;
            padding: 2%;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            .checkmark {
                position: relative;
                width: 15.15%;
                padding-top: calc(15.15% - 0.4vw);
                background-color: transparent;
                border: 0.2vw solid #ffffff;
                border-radius: 999px;
            }

            input:checked ~ .checkmark {
                background-color: transparent;
                border: 0.2vw solid #ffffff;
            }

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            input:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                top: 50%;
                left: 50%;
                width: 50%;
                height: 50%;
                border-radius: 999px;
                background: #72f4fd;
                transform: translate(-50%, -50%);
            }
        }

        .submit {
            height: 22.5%;
            border-radius: 50px;
            border: none;
            margin-top: 6.5%;
            background-color: #ffffff;
            font-family: Outage;
            font-size: 1.1vw;

            @media screen and (min-width: 1500px) {
                font-size: 17px;
            }
        }
    }

    .new-voting-results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60%;
    }

    .vote-progress {
        &.new {
            position: relative;
            height: 28.8%;
            margin-bottom: 3.5%;
            border-radius: 40px;
            background: transparent;
            border: 2px solid #7cf5fc;
            display: flex;
            align-items: center;
            padding: 2%;
        }

        height: 21%;
        border-radius: 30px;
        border: 2px solid #7cf5fc;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2% 0 4%;
    }

    .player-vote {
        border-radius: 30px 0 0 30px;
        background: #7cf5fc;
        height: 105%;
        position: absolute;
        margin-left: -1%;
        margin-top: 0.5%;
        max-width: 80%;
        left: 0;
    }

    .vote-count {
        color: #ffffff;
        font-family: DINNextLTPro-Bold;
        text-transform: uppercase;
        font-size: 0.85vw;
        width: 17%;

        @media screen and (min-width: 1500px) {
            font-size: 13px;
        }
    }

    .vote-title {
        &.player {
            color: #fff;
            flex: 1;
            text-align: left;
            margin-left: 5%;
        }

        z-index: 10;
        color: #020000;
        font-family: Ethnocentric;
        font-size: 2vw;
        position: relative;

        @media screen and (min-width: 1500px) {
            font-size: 30px;
        }
    }

    .override-wrapper {
        width: 100%;
        height: 19%;
        padding: 0 2%;
        border-top: 2px solid #7cf5fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .override-button {
        color: #02023c;
        font-family: Outage;
        height: 10%;
        border-radius: 10px;
    }

    .radio {
        position: relative;
        width: 10%;
        height: 55%;
        display: inline-block;
        margin: 0;
    }

    .top-bar {
        margin-left: 1%;
        &.hosts {
            margin-top: -5%;
            margin-left: -6%;
            max-width: 114%;
        }
    }

    .center-bar {
        margin-left: 70%;
        height: 10%;
        &.hosts {
            margin-top: -4%;
            height: 12%;
        }
    }

    .override-heading {
        font-family: Ethnocentric;
        font-size: 1.1vw;
        color: #ffffff;
        margin: 3%;

        @media screen and (min-width: 1500px) {
            font-size: 17px;
        }
    }

    .override-buttons {
        display: flex;
        height: 65%;
        justify-content: space-around;

        .override-host {
            &.yes {
                background-image: url('../../../../assets/dashboardScreen/Override_Yes.png');
            }
            &.no {
                background-image: url('../../../../assets/dashboardScreen/Override_No.png');
            }
            &.action-not-allowed {
                cursor: not-allowed;
                opacity: 0.3;
            }

            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            border: 2px solid transparent;
            width: 48%;
            height: 84%;
        }
    }

    .vote-divider {
        position: relative;
        width: 115%;
        max-width: 115%;
        margin-top: 2%;
        margin-left: -7%;
    }
}

.time-remaining {
    width: 100%;
    height: 9%;
    display: flex;
    flex-direction: row;
    border-top: 2px solid #7cf5fc;
}

.time-heading {
    &.hosts {
        font-size: 1.13vw;
        @media screen and (min-width: 1500px) {
            font-size: 17px;
        }
    }

    width: 70%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #ffffff;
    font-family: Ethnocentric;
    font-size: 1.1vw;
    border-right: 3px solid #7cf5fc;

    @media screen and (min-width: 1500px) {
        font-size: 17px;
    }
}

.time-value {
    &.hosts {
        font-size: 1.13vw;
        @media screen and (min-width: 1500px) {
            font-size: 17px;
        }
    }
    width: 30%;
    justify-content: center;
    align-self: center;
    color: #69fafe;
    font-family: Ethnocentric;
    font-size: 1.1vw;
    @media screen and (min-width: 1500px) {
        font-size: 17px;
    }
}
