.failed-container {
    position: absolute;
    width: 19.8%;
    height: 18.2%;
    margin-left: 74.8%;
    margin-top: 84.3%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        z-index: 10;
    }

    > img:first-of-type {
        width: 108%;
    }

    img {
        position: absolute;
        left: 0;
        max-width: 108%;
        margin-left: -3.7%;
        margin-top: -4.8%;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../../../../../assets/dashboardScreen/PlayerStatus_Container.png');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.3;
        z-index: 1;
    }

    .heading {
        display: flex;
        position: relative;
        width: 100%;
        height: 18%;
        color: #ffffff;
        font-family: Ethnocentric;
        justify-content: center;
        flex-direction: column;
        font-size: 2.47vw;
        line-height: 2.6vw;
        margin-bottom: 10%;
        text-align: center;

        @media screen and (min-width: 1500px) {
            font-size: 37px;
        }
    }

    .notification {
        background-image: url('../../../../../../assets/dashboardScreen/VoteFailed_Stripes.png');
        height: 36%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        border-top: 2px solid #7cf5fc;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cross-image {
        position: relative;
        left: 0;
        max-width: 27%;
        margin: 17% 0 10% 0;
    }

    .time-remaining {
        height: 12%;
    }

    .fail-notification {
        position: absolute;
        background-color: black;
        height: 27%;
        width: 80%;
        text-align: left;
        color: #ffffff;
        font-family: DINNextLTProBoldCondensed;
        font-size: 1.87vw;
        line-height: 1.73vw;

        @media screen and (min-width: 1500px) {
            font-size: 28px;
            line-height: 26px;
        }

        > div {
            padding: 9%;
        }
    }
}
