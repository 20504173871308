.messages-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.player-status-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 146.5%;
    height: 9.4%;
    width: 88.2%;
    margin-left: 6.2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-status-container {
    width: 97.2%;
    height: 82%;
    position: relative;
    display: flex;
    justify-content: center;

    &::before {
        content: '';
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('../../../../assets/hostDashboard/HostDash_InventoryContainer.png');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.3;
    }

    > img {
        position: absolute;
        left: 0;
        max-width: 101.8%;
        margin-left: -0.8%;
        margin-top: -1.1%;
        width: 101.8%;
    }
}

.status-messages-container {
    font-family: Ethnocentric;
    position: relative;
    background-color: transparent;
    border: none;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0%;
    width: 97%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    &::-webkit-scrollbar {
        height: 0.4rem;
    }
}

.each-player-status {
    width: 25%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 47.2%;
    background-image: url('../../../../assets/dashboardScreen/PlayerStatus_Player1.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: -1.25%;

    span {
        padding: 0 3%;
        display: flex;
        align-items: center;
        text-align: left;
        width: 66.5%;
    }

    .status-message {
        color: #ffffff;
        position: relative;
        font-size: 1.1vw;
        margin-left: 28.9%;
        margin-top: 0;
        text-align: left;
        height: 34%;
        white-space: nowrap;

        @media screen and (min-width: 1500px) {
            font-size: 16px;
        }
    }

    .player-name {
        color: #ffffff;
        position: relative;
        font-size: 1.15vw;
        margin-left: 29%;
        margin-top: 4.5%;
        height: 38%;

        @media screen and (min-width: 1500px) {
            font-size: 18px;
        }
    }

    .ref {
        position: absolute;
        visibility: hidden;
        height: auto;
        width: auto;
        white-space: nowrap;
        margin: 0;
    }
}
